<template>
  <b-button
    v-show="show"
    class="primary-button"
    :disabled="disabled"
    variant="primary"
    :aria-label="buttonText + ' button'"
    @click="$emit('onclick')"
  >
    <b-spinner v-if="loading" small variant="light" />

    <span v-else style="padding: 0 0.5rem">
      {{ buttonText }}
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    buttonText: {
      type: String,
      default: 'Continue',
    },
    show: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.primary-button {
  width: auto;
  min-width: 130px;
  padding: .6rem 0;
  background: $ct-ui-primary;
  border-color: $ct-ui-primary;
  border-radius: $stageline-primary-border-radius;

  &:hover {
    background: $ct-ui-primary-dark;
    border-color: $ct-ui-primary;
  }
}
</style>
